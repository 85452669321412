@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap");
$Montserrat: "Montserrat", sans-serif;

$main-color: #e44347;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $Montserrat;
  letter-spacing: -0.05rem;
  ul {
    list-style: none;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
}

::selection {
  background: $main-color;
  color: #fff;
}

$screen-md-min: 54.8rem;
$screen-lg-min: 80rem;

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

.container {
  padding: 20px 30px;
  margin: 0 auto;
  max-width: 80rem;
  width: 100%;
}

.main-header {
  background: url("../img/main-header-bg.png") center center no-repeat;
  @include md {
    height: 480px;
  }
  section {
    align-items: center;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    div {
      display: flex;
      flex-direction: column;
    }
    .text {
      align-items: center;
      display: flex;
      text-align: center;
      padding: 1.4rem 0;
      @include md {
        padding: 0;
      }
    }
  }
  .main-logo {
    max-width: 360px;
    @include md {
      margin-top: -60px;
      max-width: 440px;
    }
  }
  h2 {
    margin: 1.6rem 0;
    color: #fff;
    font-weight: 400;
  }
  .poker-chips {
    display: none;
    @include md {
      display: inherit;
    }
    width: 480px;
  }
}

.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include md {
    flex-direction: row;
  }
}

.whatsapp-button {
  align-items: center;
  background-color: #25d366;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: $Montserrat;
  height: 50px;
  text-transform: uppercase;
  svg {
    background-color: #075e54;
    fill: #fff;
    padding: 12px;
    height: 50px;
    width: 50px;
  }
  h3 {
    font-size: 1rem;
    @include lg {
      font-size: 1.2rem;
    }
    font-weight: 800;
  }
  div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  transition: background-color ease 0.2s;
}
.whatsapp-button:hover,
.whatsapp-button:active {
  background-color: #30df70;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.15));
}

.app-button {
  align-items: center;
  background-color: #0e6efd;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: $Montserrat;
  height: 50px;
  text-transform: uppercase;
  svg {
    background-color: #075e54;
    fill: #fff;
    padding: 12px;
    height: 50px;
    width: 50px;
  }
  h3 {
    font-size: 1rem;
    @include lg {
      font-size: 1.2rem;
    }
    font-weight: 800;
  }
  div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  transition: background-color ease 0.2s;
}
.app-button:hover,
.app-button:active {
  background-color: #167cfa;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.15));
}

.about-button {
  align-items: center;
  background-color: #e44347;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: $Montserrat;
  margin-bottom: 20px;
  height: 50px;
  text-transform: uppercase;
  @include md {
    margin-bottom: 0;
  }
  svg {
    background-color: #e6aa47;
    fill: #fff;
    padding: 12px;
    height: 50px;
    width: 50px;
  }
  h3 {
    font-size: 1rem;
    @include lg {
      font-size: 1.2rem;
    }
    font-weight: 800;
  }
  div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  transition: background-color ease 0.2s;
}
.about-button:hover,
.about-button:active {
  background-color: #ba292b;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.15));
}

.main-content {
  text-align: center;
  h2 {
    margin-top: 2rem;
    font-weight: 400;
    b {
      color: $main-color;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include md {
      justify-content: space-between;
      flex-direction: row;
    }
    padding: 2rem 0;
    .desktop {
      display: none !important;
      @include md {
        display: flex !important;
      }
    }
    .mobile {
      display: flex !important;
      @include md {
        display: none !important;
      }
    }
    .box {
      margin-bottom: 1rem;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      background-color: #f8f8f8;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
      padding: 2rem;
      img {
        max-width: 220px;
      }
      h3 {
        font-size: 1rem;
        font-weight: 800;
        margin-top: 1rem;
      }
      a,
      a:visited {
        color: #fff;
        font-weight: 800;
        text-transform: uppercase;
        padding: 1rem;
        background-color: $main-color;
        transition: background-color ease 0.2s;
      }
      a:hover,
      a:active {
        background-color: #ba292b;
      }
    }
  }
}

.main-footer {
  .contact {
    align-items: center;
    display: flex;
    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      .buttons {
        display: flex;
        justify-content: center;
        a,
        a:visited {
          background-color: $main-color;
          align-items: center;
          display: flex;
          padding: 1rem;
          font-size: 1.4rem;
          color: #fff;
          margin-right: 1rem;
          transition: background-color ease 0.2s;
        }
        a:hover,
        a:active {
          background-color: #ba292b;
        }
      }
    }
    background: url("../img/main-footer-bg.png") no-repeat;
    background-size: cover;
    height: 280px;
    text-align: center;
  }

  h2 {
    margin: 1.6rem 0;
    color: #fff;
    font-weight: 400;
  }
  .copyright {
    background-color: #000;
    color: #fff;
    img {
      max-width: 130px;
    }
    div {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 90%;
  max-width: 640px;
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}

.modal {
  h2 {
    color: #e44347;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  p {
    line-height: 1.4rem;
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
}
